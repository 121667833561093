import role from '@/auth/role'

export default [
  // customer
  {
    title: 'navigation.dashboard',
    route: 'customer-dashboard',
    icon: 'BarChart2Icon',
    acl: [role.student, role.guardian],
  },
  {
    title: 'navigation.meeting-list',
    route: 'customer-meeting-list',
    icon: 'ListIcon',
    acl: [role.student, role.guardian],
  },
  {
    title: 'navigation.settlements',
    route: 'customer-settlements',
    icon: 'DollarSignIcon',
    acl: [role.student, role.guardian],
  },
  {
    title: 'navigation.contacts',
    route: 'customer-contacts',
    icon: 'UsersIcon',
    acl: [role.student, role.guardian],
  },

  {
    title: 'navigation.help-center',
    href: 'https://help.totedi.com/panel-klienta',
    icon: 'HelpCircleIcon',
    class: 'mt-3',
    acl: [role.student, role.guardian],
  },

  // organization
  {
    title: 'navigation.dashboard',
    route: 'organization-dashboard',
    icon: 'BarChart2Icon',
    acl: [role.admin, role.educator],
  },
  {
    title: 'navigation.calendar',
    route: 'organization-calendar',
    icon: 'CalendarIcon',
    acl: [role.admin, role.educator],
  },
  {
    title: 'navigation.customers',
    route: 'organization-customers',
    icon: 'UsersIcon',
    acl: [role.admin, role.educator],
  },
  {
    title: 'navigation.scripts',
    icon: 'FileTextIcon',
    class: 'app-tour-vertical-menu-organization-scripts',
    children: [
      {
        title: 'navigation.scripts',
        route: 'organization-script-list',
        acl: [role.admin, role.educator],
      },
      {
        title: 'navigation.script-groups',
        route: 'organization-script-groups',
        // icon: 'BookOpenIcon',
        acl: [role.admin, role.educator],
      },
    ],
    acl: [role.admin, role.educator],
  },

  {
    title: 'navigation.services',
    route: 'organization-services',
    icon: 'ListIcon',
    class: 'mt-3',
    acl: [role.admin],
  },
  {
    title: 'navigation.settlements',
    route: 'settlements-list',
    icon: 'DollarSignIcon',
    badge: 'light-danger',
    acl: [role.admin],
  },
  {
    title: 'navigation.integrations',
    route: 'organization-integrations',
    icon: 'LinkIcon',
    acl: [role.admin],
  },
  {
    title: 'navigation.account-settings',
    icon: 'SettingsIcon',
    class: 'app-tour-vertical-menu-organization-account-settings',
    acl: [role.admin],
    children: [
      {
        title: 'navigation.settings.company-profile',
        route: 'organization-settings-company-profile',
        icon: 'BriefcaseIcon',
        acl: [role.admin],
      },
      {
        title: 'navigation.settings.plan-and-billing',
        route: 'organization-settings-plan-and-billing',
        icon: 'CreditCardIcon',
        acl: [role.admin],
      },
      {
        title: 'navigation.settings.educators',
        route: 'organization-settings-educators-list',
        icon: 'UsersIcon',
        acl: [role.admin],
      },
    ],
  },

  {
    title: 'navigation.help-center',
    href: 'https://help.totedi.com',
    icon: 'HelpCircleIcon',
    class: 'mt-3',
    acl: [role.educator],
  },
]
