<template>
  <li :class="prepareClass()">
    <b-link
      v-acl="item.acl ? item.acl : []"
      v-bind="linkProps"
      class="d-flex align-items-center mt-50"
    >
      <feather-icon
        v-if="!me.roles.includes(role.guardian) && !me.roles.includes(role.student) && !me.organization.subscriptionActive"
        icon="LockIcon"
      />
      <feather-icon
        v-else
        :icon="item.icon || 'CircleIcon'"
      />
      <span class="menu-title text-truncate">
        {{ $t(item.title) }}
      </span>
      <b-badge
        v-if="item.tag"
        pill
        :variant="item.tagVariant || 'primary'"
        class="mr-1 ml-auto"
      >
        {{ item.tag }}
      </b-badge>
      <div
        v-if="item.tagIcon"
        class="d-inline-block ml-auto"
      >
        <feather-icon
          :icon="item.tagIcon"
          :class="item.tagIconClass || 'text-warning'"
          :size="item.tagIconSize || '18'"
        />
      </div>
    </b-link>
  </li>
</template>

<script>
import { BBadge, BLink } from 'bootstrap-vue'
import useVerticalNavMenuLink from './useVerticalNavMenuLink'
import mixinVerticalNavMenuLink from './mixinVerticalNavMenuLink'
import role from '@/auth/role'

export default {
  components: {
    BLink,
    BBadge,
  },
  mixins: [mixinVerticalNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    me() {
      return this.$store.getters['auth/getUser']
    },
  },
  setup(props) {
    const { isActive, linkProps, updateIsActive } = useVerticalNavMenuLink(props.item)

    const prepareClass = () => {
      const cssClass = []

      if (isActive.value) {
        cssClass.push('active')
      }
      if (props.item.disabled) {
        cssClass.push('disabled')
      }
      if (props.item.class) {
        cssClass.push(props.item.class)
      }

      cssClass.push(`app-tour-vertical-menu-${props.item.route}`)

      return cssClass.join(' ')
    }

    return {
      role,
      isActive,
      linkProps,
      updateIsActive,
      prepareClass,
    }
  },

}
</script>
