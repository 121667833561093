<template>
  <p class="clearfix mb-0">
    <span class="float-left d-block d-md-inline-block mt-25">
      <span>Powered by</span>
      <b-link
        class="ml-25"
        href="https://totedi.com"
        target="_blank"
        rel="nofollow"
      >Totedi</b-link>
      <span class="d-none d-md-inline-block ml-25">- Planer Nauczyciela</span>
    </span>

    <span
      v-if="version"
      class="float-right mt-25"
    >
      <span class="d-none d-md-inline-block">{{ $t('shared.version') }}:</span> {{ version }}
    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'
import ax from 'axios'
import dayjs from 'dayjs'

export default {
  components: {
    BLink,
  },
  data() {
    return {
      version: null,
    }
  },
  mounted() {
    ax.get(`${window.location.origin}/version.json?${dayjs().unix()}`).then(res => {
      localStorage.setItem('version', res.data.version)
      this.version = res.data.version
    })
  },
}
</script>
