<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link app-tour-menu-toggle-button"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <!--    <locale />-->
      <!--    <dark-Toggler class="d-none d-lg-block" />-->
      <!--    <search-bar />-->
      <!--    <cart-dropdown />-->
      <!--    <notification-dropdown />-->

      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link ml-3"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0 text-right">
              {{ me.firstName }} {{ me.lastName }}
            </p>
            <span class="user-status">{{ me.email }}</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            class="badge-minimal app-tour-navbar-profile-avatar"
            badge-variant="success"
          >
            {{ initials }}
          </b-avatar>
        </template>

        <!--        <b-dropdown-item link-class="d-flex align-items-center">-->
        <!--          <feather-icon-->
        <!--            size="16"-->
        <!--            icon="UserIcon"-->
        <!--            class="mr-50"-->
        <!--          />-->
        <!--          <span>Profile</span>-->
        <!--        </b-dropdown-item>-->

        <!--        <b-dropdown-item link-class="d-flex align-items-center">-->
        <!--          <feather-icon-->
        <!--            size="16"-->
        <!--            icon="MailIcon"-->
        <!--            class="mr-50"-->
        <!--          />-->
        <!--          <span>Inbox</span>-->
        <!--        </b-dropdown-item>-->

        <!--        <b-dropdown-item link-class="d-flex align-items-center">-->
        <!--          <feather-icon-->
        <!--            size="16"-->
        <!--            icon="CheckSquareIcon"-->
        <!--            class="mr-50"-->
        <!--          />-->
        <!--          <span>Task</span>-->
        <!--        </b-dropdown-item>-->

        <!--        <b-dropdown-item link-class="d-flex align-items-center">-->
        <!--          <feather-icon-->
        <!--            size="16"-->
        <!--            icon="MessageSquareIcon"-->
        <!--            class="mr-50"-->
        <!--          />-->
        <!--          <span>Chat</span>-->
        <!--        </b-dropdown-item>-->

        <!--        <b-dropdown-divider />-->

        <b-dropdown-item
          v-if="me.organization && ['Trial'].includes(me.organization.subscriptionType)"
          v-acl="[role.admin]"
          link-class="d-flex align-items-center"
          @click="$router.push({name: 'checkout'})"
        >
          <feather-icon
            size="16"
            icon="ShoppingCartIcon"
            class="mr-50"
          />
          <span> {{ $t('navigation.user.select-subscription') }}</span>
        </b-dropdown-item>

        <b-dropdown-item
          v-if="me.organization && ['Yearly', 'Monthly',].includes(me.organization.subscriptionType)"
          v-acl="[role.admin]"
          link-class="d-flex align-items-center"
          href="https://billing.stripe.com/p/login/eVa5nU2X8bkSbxm8ww"
          target="_blank"
        >
          <feather-icon
            size="16"
            icon="CreditCardIcon"
            class="mr-50"
          />
          <span> {{ $t('navigation.user.manage-subscription') }}
            <feather-icon
              size="8"
              icon="ExternalLinkIcon"
              class="text-muted"
            />
          </span>
        </b-dropdown-item>

        <b-dropdown-divider
          v-if="me.organization && ['Yearly', 'Monthly', 'Trial'].includes(me.organization.subscriptionType)"
          v-acl="[role.admin]"
        />

        <b-dropdown-item
          v-acl="[role.educator]"
          link-class="d-flex align-items-center"
          href="https://help.totedi.com"
          target="_blank"
        >
          <feather-icon
            size="16"
            icon="HelpCircleIcon"
            class="mr-50"
          />
          <span> {{ $t('navigation.help-center') }}</span>
        </b-dropdown-item>

        <b-dropdown-item
          v-acl="[role.student, role.guardian]"
          link-class="d-flex align-items-center"
          href="https://help.totedi.com/panel-klienta"
          target="_blank"
        >
          <feather-icon
            size="16"
            icon="HelpCircleIcon"
            class="mr-50"
          />
          <span> {{ $t('navigation.help-center') }}</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span> {{ $t('auth.logout') }}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>

    <password-change-popup
      v-if="me.requirePasswordChange"
      :change-required="true"
    />
  </div>
</template>

<script>
import {
  BAvatar, BDropdownItem, BLink, BNavbarNav, BNavItemDropdown,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import { useDocumentVisibility } from '@vueuse/core'
import PasswordChangePopup from '@core/layouts/components/popups/PasswordChangePopup.vue'
import router from '@/router'
import role from '@/auth/role'

export default {
  components: {
    PasswordChangePopup,
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BAvatar,

    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      role,
    }
  },
  computed: {
    me() {
      return this.$store.getters['auth/getUser']
    },
    initials() {
      if (!this.me.id) {
        return null
      }

      return this.me.firstName.charAt(0) + this.me.lastName.charAt(0)
    },
    visibility() {
      return useDocumentVisibility().value === 'visible'
    },
  },
  watch: {
    visibility: {
      handler: 'onIsDocumentVisible',
    },
  },
  beforeMount() {
    if (!this.me.id) {
      this.fetchUserInfo()
    }
  },
  methods: {
    fetchUserInfo() {
      this.$store.dispatch('auth/fetchUserInfo')
        .catch(err => {
          if (err?.code === 403) {
            this.logout()
          }
        })
    },
    logout() {
      if (this.me.id) {
        this.$store.dispatch('auth/logout')
          .finally(() => {
            router.push({ name: 'login' })
            window.toast.notify.success(this.$t('auth.logout-success'))
          })
      } else {
        router.push({ name: 'login' })
      }
    },
    onIsDocumentVisible(state) {
      if (state) {
        this.fetchUserInfo()
      }
    },
  },
}
</script>
