<template>
  <li :class="prepareClass()">
    <b-link
      class="d-flex align-items-center mt-50"
      @click="() => updateGroupOpen(!isOpen)"
    >
      <feather-icon
        v-if="!me.roles.includes(role.guardian) && !me.roles.includes(role.student) && !me.organization.subscriptionActive"
        icon="LockIcon"
      />
      <feather-icon
        v-else
        :icon="item.icon || 'CircleIcon'"
      />
      <span class="menu-title text-truncate">{{ $t(item.title) }}</span>
      <b-badge
        v-if="item.tag"
        pill
        :variant="item.tagVariant || 'primary'"
        class="mr-1 ml-auto"
      >
        {{ item.tag }}
      </b-badge>
      <div
        v-if="item.tagIcon"
        class="d-inline-block ml-auto"
      >
        <feather-icon
          :icon="item.tagIcon"
          :class="item.tagIconClass || 'text-warning'"
          :size="item.tagIconSize || '18'"
        />
      </div>
    </b-link>
    <b-collapse
      v-model="isOpen"
      class="menu-content"
      tag="ul"
    >
      <component
        :is="resolveNavItemComponent(child)"
        v-for="child in item.children"
        :key="child.header || child.title"
        ref="groupChild"
        :item="child"
      />
    </b-collapse>
  </li>
</template>

<script>
import { BBadge, BCollapse, BLink } from 'bootstrap-vue'
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils'
import VerticalNavMenuHeader from '../vertical-nav-menu-header'
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'

// import { ref } from '@vue/composition-api'
// Composition Function
import useVerticalNavMenuGroup from './useVerticalNavMenuGroup'
import mixinVerticalNavMenuGroup from './mixinVerticalNavMenuGroup'
import role from '@/auth/role'

export default {
  name: 'VerticalNavMenuGroup',
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    BLink,
    BBadge,
    BCollapse,
  },
  mixins: [mixinVerticalNavMenuGroup],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    me() {
      return this.$store.getters['auth/getUser']
    },
  },
  setup(props) {
    const {
      isOpen,
      isActive,
      updateGroupOpen,
      updateIsActive,
    } = useVerticalNavMenuGroup(props.item)

    const prepareClass = () => {
      const cssClass = ['nav-item', 'has-sub']

      if (isActive.value) {
        cssClass.push('sidebar-group-active')
      }
      if (isOpen.value) {
        cssClass.push('open')
      }
      if (props.item.disabled) {
        cssClass.push('disabled')
      }
      if (props.item.class) {
        cssClass.push(props.item.class)
      }

      return cssClass.join(' ')
    }

    return {
      role,
      resolveNavItemComponent,
      isOpen,
      isActive,
      updateGroupOpen,
      updateIsActive,
      prepareClass,
    }
  },
}
</script>

<style>

</style>
